// src/LandingPage.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./LandingPage.css";
import Footer from "./components/Footer"; // Importar o rodapé
import Header from "./components/Header";
import buffetImage from '../src/images/ilha_buffet.jpg';
import cadeiraImage from '../src/images/Cadeiras_de_Festa .jpeg';
import mesaImage from '../src/images/mesa_festa.jpeg';
import ledImage from '../src/images/led.jpeg';
import dfImage from '../src/images/dj.jpeg';
import tendaImage from '../src/images/tendas.jpeg';
import decoracaoImage from '../src/images/decoracao.jpeg';
import inflavelImage from '../src/images/inflavel.jpeg';
import SuperAliny from './components/SuperAliny';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleExperimentClick = () => {
    navigate("/catalog");
  };

  return (
    <div className="landing-page">
      {/* Header */}
      <Header />

      {/* Conteúdo Principal */}
      <main className="main-content">
        {/* Seção Hero */}
        <section className="hero-section">
          <h1>Organize seu orçamento de festa de forma rápida e fácil</h1>
          <p>
            Com o FestaFácil, você tem controle total sobre os custos do seu
            evento. Planeje, ajuste e acompanhe todas as suas despesas em um só
            lugar, garantindo que sua festa seja inesquecível sem surpresas no
            orçamento.
          </p>          
          <button className="btn-cta" onClick={handleExperimentClick}>
            Experimente Agora
          </button>
          {/* <p>Super Aliny</p><SuperAliny/> */}
        </section>

        {/* Seção de Benefícios */}
        <section className="benefits-section">
          <h2>Por que escolher o FestaFácil?</h2>
          <div className="benefits-grid">
            <div className="benefit">
              <h3>Facilidade de Uso</h3>
              <p>
                Interface simples e intuitiva para que qualquer pessoa possa
                gerenciar o orçamento da festa sem complicação, mesmo sem
                conhecimentos técnicos.
              </p>
            </div>
            <div className="benefit">
              <h3>Economize Tempo</h3>
              <p>
                Automatize cálculos de despesas e acompanhe as mudanças em tempo
                real. Gaste menos tempo ajustando o orçamento e mais tempo
                aproveitando a festa.
              </p>
            </div>
            <div className="benefit">
              <h3>Orçamento em Detalhes</h3>
              <p>
                Visualize todas as categorias de despesas e obtenha relatórios
                detalhados sobre os custos da sua festa, garantindo que nada
                seja esquecido.
              </p>
            </div>
            <div className="benefit">
              <h3>Colaboração Facilitada</h3>
              <p>
                Convide outras pessoas para colaborar no planejamento
                financeiro, como fornecedores, familiares e amigos, e centralize
                todas as informações.
              </p>
            </div>
          </div>
        </section>

        {/* Seção de Produtos Populares */}
        <section className="products-section">
          <h2>Itens Mais Alugados</h2>
          <div className="products-grid">
            <div className="product-card">
              <img src={cadeiraImage} alt="Cadeiras de Festa" />
              <h3>Cadeiras de Festa</h3>
              <p>
                Cadeiras confortáveis para acomodar seus convidados com estilo.
              </p>
            </div>
            <div className="product-card">
              <img src={mesaImage} alt="Mesas de Festa" />
              <h3>Mesas de Festa</h3>
              <p>Mesas grandes e elegantes para festas e buffets.</p>
            </div>
            <div className="product-card">
              <img src={ledImage} alt="Iluminação" />
              <h3>Iluminação LED</h3>
              <p>
                Iluminação colorida e ajustável para criar o ambiente perfeito.
              </p>
            </div>
            <div className="product-card">
              <img src={dfImage} alt="Sistema de Som e DJ" />
              <h3>Sistema de Som e DJ</h3>
              <p>
                Equipamento de som completo com opções de DJ para festas
                animadas.
              </p>
            </div>
            <div className="product-card">
              <img src={tendaImage} alt="Tendas" />
              <h3>Tendas para Eventos</h3>
              <p>
                Tendas resistentes para proteger seus convidados em festas ao ar
                livre.
              </p>
            </div>
            <div className="product-card">
              <img src={decoracaoImage} alt="Decoração" />
              <h3>Decoração Temática</h3>
              <p>
                Decorações criativas e personalizadas para deixar sua festa
                incrível.
              </p>
            </div>
            <div className="product-card">
              <img src={buffetImage} alt="Ilhas de Buffet" />
              <h3>Ilhas de Buffet</h3>
              <p>
                Mesas de buffet para servir comidas e bebidas de maneira prática
                e elegante.
              </p>
            </div>
            <div className="product-card">
              <img src={inflavelImage} alt="Brinquedos Infláveis" />
              <h3>Brinquedos Infláveis</h3>
              <p>
                Diversão garantida para as crianças com brinquedos infláveis
                coloridos.
              </p>
            </div>
          </div>
          <button className="btn-catalog" onClick={() => navigate("/catalog")}>
            Veja o Catálogo Completo
          </button>
        </section>

        {/* Seção de Depoimentos */}
        <section className="testimonial-section">
          <h2>O que nossos usuários dizem</h2>
          <div className="testimonials">
            <blockquote>
              “O FestaFácil me ajudou a organizar minha festa de casamento sem
              dor de cabeça. Consegui planejar tudo com antecedência e fiquei
              tranquila sabendo que o orçamento estava sob controle.”
              <footer>- Maria F., São Paulo</footer>
            </blockquote>
            <blockquote>
              “Utilizar o FestaFácil foi a melhor decisão para a festa de
              aniversário do meu filho. A ferramenta é muito prática e nos
              ajudou a manter os custos dentro do previsto.”
              <footer>- João S., Rio de Janeiro</footer>
            </blockquote>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default LandingPage;
