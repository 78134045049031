// src/LoginPage.js
import React, { useState, useEffect } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode"
import './LoginPage.css';
import Footer from './components/Footer';
import Header from './components/Header';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const message = queryParams.get('message');

    if (message === 'sessao-expirada') {
      setErrorMessage('Sua sessão expirou. Faça login novamente.');
    }
  }, [location]);

  const handleLogin = () => {
    setErrorMessage(''); // Limpa qualquer mensagem de erro anterior

    
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/usuario/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Modificado para enviar JSON
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) =>
        response.json().then((data) => ({ status: response.status, body: data }))
      )
      .then(({ status, body }) => {
        console.log(status , "  " , body);
        if (status === 200 && body.token) { // Ajustado para status 200 e campo `token`
          const jwtToken = body.token;
          console.log("aqui passou irmão ")
          localStorage.setItem('jwt_token', jwtToken); // Armazena o token no localStorage
          navigate(`/dashboard?token=${jwtToken}`); // Redireciona para o dashboard
        } else {
          setErrorMessage(body.message || 'Falha no login. Tente novamente.');
        }
      })
      .catch((error) => {
        setErrorMessage('Erro ao fazer login: ' + error.message);
      });
  };
  

  const handleGoogleSuccess = (response) => {
    const decoded = jwtDecode(response.credential);
    console.log("bosta " + decoded);

    if (decoded.email) {
      // Verifica se o usuário existe ou cria um novo
      fetch('https://teste.stratusport.co/festafacil/users.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          google_id: decoded.sub,
          email: decoded.email,
          name: decoded.name
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json(); // Retorna a resposta JSON se disponível
          } else if (response.status === 204) {
            // Caso o status seja 204 (sem conteúdo), retorna um objeto vazio
            return {};
          } else {
            throw new Error('Erro ao verificar ou criar o usuário');
          }
        })
        .then((data) => {
          if (data.success || data.user_exists) {
            // Se o usuário já existe, faz o login automaticamente com a senha dummy
            fetch('https://teste.stratusport.co/festafacil/login.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: new URLSearchParams({
                email: decoded.email,
                password: 'dummy_password', // Senha dummy usada para login via Google
              }),
            })
              .then((response) => {
                if (response.ok) {
                  return response.json();
                } else {
                  throw new Error('Erro ao fazer login após Google');
                }
              })
              .then((loginData) => {
                if (loginData.jwt) {
                  localStorage.setItem('jwt_token', loginData.jwt); // Armazena o token
                  navigate(`/dashboard?token=${loginData.jwt}`); // Redireciona para o dashboard
                } else {
                  setErrorMessage('Erro ao fazer login após Google: ' + loginData.message);
                }
              })
              .catch((error) => {
                setErrorMessage('Erro ao fazer login após Google: ' + error.message);
              });
          } else {
            setErrorMessage('Falha ao criar ou verificar o usuário: ' + data.message);
          }
        })
        .catch((error) => {
          setErrorMessage('Erro ao criar/verificar usuário via Google: ' + error.message);
        });
    } else {
      setErrorMessage('Falha ao obter o e-mail da conta Google. Tente novamente.');
    }
  };

  const handleGoogleError = () => {
    setErrorMessage('Erro ao fazer login com Google');
  };

  return (
    <GoogleOAuthProvider clientId="1081915148314-tg3q95e7llfpm9csl8elkmfjnpngjn2b.apps.googleusercontent.com">
      <div className="login-page">
        <Header/>

        <main className="login-content">
          <div className="login-form">
            <h2>Login</h2>
            {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Exibe mensagens de erro */}
            <div className="input-group">
              <label htmlFor="email">E-mail:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label htmlFor="password">Senha:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button className="btn-login" onClick={handleLogin}>
              Login
            </button>
            <div className="or-separator">ou</div>
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleError}
            />
          </div>
        </main>
      </div>
      <Footer/>
    </GoogleOAuthProvider>
  );
};

export default LoginPage;
