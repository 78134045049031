import React, { useState } from 'react';
import './Footer.css'; // Arquivo CSS para estilização

const Footer = () => {
  const [cookieConsent, setCookieConsent] = useState(false);

  const handleAcceptCookies = () => {
    setCookieConsent(true);
    localStorage.setItem('cookieConsent', 'true');
  };

  // Verifica se o usuário já aceitou os cookies
  useState(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent) {
      setCookieConsent(true);
    }
  }, []);

  return (
    <>
      <footer className="footer">
        <div className="footer-column">
          <h3>Sobre Nós</h3>
          <p>
            Feito com <span>&hearts;</span> em São Paulo para tornar a organização
            da sua festa mais fácil e divertida!
          </p>
        </div>
        <div className="footer-column">
          <h3>Links</h3>
          <ul>
            <li>
              <a href="#features">Seja um fornecedor</a>
            </li>
            <li>
              <a href="#pricing">Politica de privacidade / Termos e condições</a>
            </li>
            <li>
              <a href="#contact">Contato</a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Informações Rápidas</h3>
          <p>Email: suporte@festafacil.com.br</p>
          <p>Telefone: (11) 1234-5678</p>
        </div>
      </footer>

      {!cookieConsent && (
        <div className="cookie-consent">
          <p>
            Este site usa cookies para garantir que você tenha a melhor experiência.
            Ao continuar navegando, você aceita nossa{' '}
            <a href="#privacy-policy">política de privacidade</a>.
          </p>
          <button className="accept-cookies-btn" onClick={handleAcceptCookies}>
            Aceitar
          </button>
        </div>
      )}
    </>
  );
};

export default Footer;
