import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { jwtDecode } from "jwt-decode";
import "./Dashboard.css"; // Estilos do Dashboard
import News from "./components/News"; // Importa o novo componente de notícias

const Dashboard = () => {
  const [userName, setUserName] = useState("");
  const [items, setItems] = useState([]); // Agora chamamos de `items` ao invés de `cartItems`
  const [eventDate, setEventDate] = useState("");
  const [eventDuration, setEventDuration] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [totalPrice, setTotalPrice] = useState(null); // O total_price será zero inicialmente
  const [amountPeople, setAmountPeople] = useState(""); // Valor padrão para 0
  const navigate = useNavigate();
  const location = useLocation(); // Pegar a localização atual da URL


  useEffect(() => {
    try {
      const token = localStorage.getItem("jwt_token");
      if (token) {
        const decodedToken = jwtDecode(token);

        // Verifica se o token contém os dados esperados
        if (decodedToken && decodedToken && decodedToken.name) {
          setUserName(decodedToken.name);
        } else {
          throw new Error("Token inválido. " + token);
        }

        // Verificar se há um orçamento salvo no localStorage
        const savedBudget = localStorage.getItem("savedBudget");
        console.log("Conteúdo de savedBudget:", savedBudget);

        if (savedBudget) {
          const parsedBudget = JSON.parse(savedBudget);
          console.log("Parsed Budget:", parsedBudget);

          // Atualizando para refletir a estrutura `items`
          if (Array.isArray(parsedBudget.items)) {
            console.log("Itens no carrinho:", parsedBudget.items);
            setItems(parsedBudget.items.map((item) => ({ ...item, quantity: 1 }))); // Definindo a quantidade padrão como 1
          }

          setEventDate(parsedBudget.eventDate || "");
          setEventDuration(parsedBudget.eventDuration || "");
          setEventLocation(parsedBudget.eventLocation || "");
          setAmountPeople(parsedBudget.amountPeople || 0); // Definir padrão para 0
        } else {
          console.log("Nenhum orçamento salvo encontrado.");
        }
      } else {
        // Se não houver token, redireciona para a tela de login
        navigate("/login");
      }
    } catch (error) {
      console.error("Erro ao carregar dados do dashboard:", error);
      navigate("/login"); // Redireciona para login em caso de erro
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("jwt_token");
    navigate("/login");
  };

  const saveBudget = async () => {
    try {
      // Decodificar o token JWT para obter o userId
      const userId = jwtDecode(localStorage.getItem("jwt_token")).id;
      const params = new URLSearchParams(location.search);
      const token = params.get("token");
  
      // Carregar o JSON do orçamento salvo no localStorage
      const savedBudget = JSON.parse(localStorage.getItem("savedBudget"));
  
      // Verificar se há itens no orçamento
      const items = savedBudget.items;
      if (!items || items.length === 0) {
        throw new Error("Nenhum item foi adicionado ao orçamento.");
      }
  
      // Array para armazenar os IDs dos BudgetItems
      const itemIds = [];
  
      // Passo 1: Criar os BudgetItems
      for (const item of items) {
        const budgetItemData = {
          product_service_id: item.product_service_id,
          quantity: 1, // Pode ser alterado se necessário
          price: item.price || 0 // Permitir preço 0
        };
  
        // Salvar cada item como um BudgetItem no banco
        const itemResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/budgetItens`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",              
            },
            body: JSON.stringify(budgetItemData),
          }
        );
  
        const itemResult = await itemResponse.json();
        if (itemResponse.ok) {
          // Adicionar o ID do BudgetItem salvo ao array de item IDs
          itemIds.push(itemResult._id);
        } else {
          console.error("Erro ao salvar o item:", itemResult);
          alert("Erro ao salvar um item do orçamento.");
          return;
        }
      }
  
      // Passo 2: Criar o orçamento (Budget) com os IDs dos BudgetItems
      const budgetData = {
        user_id: userId,
        event_date: savedBudget.eventDate, // Data do evento
        duration: savedBudget.eventDuration, // Duração do evento
        location: savedBudget.eventLocation, // Localização do evento
        total_price: 0, // Definir como 0, pois o preço será atualizado
        amount_people: savedBudget.amountPeople, // Quantidade de pessoas no evento
        items: itemIds // Usar os IDs dos BudgetItems criados
      };
  
      // Salvar o orçamento no banco
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/budgets`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("jwt_token")}`
          },
          body: JSON.stringify(budgetData),
        }
      );
  
      const result = await response.json();
      if (response.ok) {
        alert("Orçamento e itens criados com sucesso!");
  
        // Eliminar as informações no localStorage para evitar conflitos futuros
        localStorage.removeItem("savedBudget");
  
        // Redirecionar para a tela de orçamentos
        navigate(`/budgets?token=${encodeURIComponent(token)}`);
      } else {
        console.log(result);
        alert("Erro ao salvar o orçamento.");
      }
    } catch (error) {
      console.log("Erro ao criar o orçamento e itens:", error);
    }
  
  };
  
  

  // Função para enviar as cotações para os fornecedores
  const sendCotationToSuppliers = async (budgetData) => {
    try {
      await fetch("https://teste.stratusport.co/festafacil/send_cotation.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(budgetData), // Enviar os dados formatados
      })
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            console.log(response);
            throw new Error("Resposta inválida do servidor. Esperava JSON.");
          }
        })
        .then((data) => {
          if (data.success) {
            alert("Solicitações de cotação enviadas com sucesso!");
          } else {
            alert("Erro ao enviar solicitações de cotação.");
          }
        })
        .catch((error) => {
          console.error("Erro:", error);
          alert("Erro ao enviar solicitações de cotação: " + error.message);
        });
    } catch (error) {
      console.error("Erro ao enviar cotações:", error);
    }
  };

  // Atualiza a quantidade de itens no estado
  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, quantity: newQuantity } : item
    );
    setItems(updatedItems);
  };

  return (    
    <div className="dashboard-container">
      <Header />      
      <main className="dashboard-content">
      
        <h1>{userName} Bem-vindo ao FestaFácil</h1>
        {items.length > 0 ? (
          <>
            <h2>Seu Orçamento</h2>
            <ul>
              {items.map((item, index) => (
                <li key={index} className="cart-item">
                  <h3>{item.name}</h3>
                  <p>{item.description}</p>
                  <p className="location">Localidade: {item.location}</p>

                  {/* Campo de Quantidade Editável */}
                  <div className="quantity-field">
                    <label htmlFor={`quantity-${index}`}>Quantidade:</label>
                    <input
                      type="number"
                      id={`quantity-${index}`}
                      min="1"
                      value={item.quantity}
                      onChange={(e) =>
                        handleQuantityChange(index, parseInt(e.target.value))
                      }
                    />
                  </div>

                  {/* Listagem de fornecedores que responderam */}
                  {item.suppliers && item.suppliers.length > 0 ? (
                    <div className="suppliers">
                      <h4>Fornecedores que responderam:</h4>
                      <ul className="supplier-list">
                        {item.suppliers.map((supplier, supplierIndex) => (
                          <li key={supplierIndex} className="supplier">
                            {supplier.name}: <span>{supplier.price}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <p>Nenhum fornecedor respondeu.</p>
                  )}
                </li>
              ))}
            </ul>
            <div className="cart-details">
              <p>
                <strong>Data do Evento:</strong> {eventDate}
              </p>
              <p>
                <strong>Total de convidados:</strong> {amountPeople}
              </p>
              <p>
                <strong>Duração:</strong> {eventDuration}
              </p>
              <p>
                <strong>Localidade:</strong> {eventLocation}
              </p>
            </div>
            <button onClick={saveBudget} className="btn-save">
              Salvar Orçamento
            </button>
          </>
        ) : (
          <p>Seu carrinho está vazio.</p>                    
        )}                
      </main>   
      <News /> {/* Adiciona a seção de notícias */}   
      <Footer />      
    </div>
  );
};

export default Dashboard;



