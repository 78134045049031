import React, { useEffect, useState } from "react";
import "./News.css";

const News = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      const response = await fetch(
        "https://teste.stratusport.co/festafacil/news.php"
      );
      const result = await response.json();
      setNews(result);
    };

    fetchNews();
  }, []);

  return (
    <div className="news-container">
      <h2 className="news-title">Notícias</h2>
      <div className="news-grid">
        {news.map((article) => (
          <div key={article.id} className="news-card">
            <div className="news-header">
              <h3 className="news-card-title">{article.title}</h3>              
            </div>
            <p className="news-card-date">
                {article.date} às {article.time}
              </p>
            <p className="news-card-text">{article.text}</p>
            <div className="news-footer">              
              <button className="news-button">Saiba Mais</button>
            </div>
            {/* Rodapé discreto com "Powered by FestaFácil" */}
            <div className="powered-by-festafacil">
              <p>
                Powered by <strong>FestaFácil</strong>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
